import React, { Component } from "react";
import "./Style.css";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import Slider from "react-slick";

export default class Seasonal_Recipes extends Component {
  constructor() {
    super();
    this.state = {
      recipes: [],
      banner_img: [],
      base_url: global.BaseUrl,
      assets_url: global.AssetsUrl,
    };
  }

  componentDidMount() {
    fetch(`${this.state.base_url}/getSeasonalRecipes`).then((resp) => {
      resp.json().then((result) => {
        this.setState({ recipes: result });
      });
    });

    fetch(`${this.state.base_url}/getBannerById/6`).then((resp) => {
      resp.json().then((result) => {
        //  console.log(result);
        this.setState({ banner_img: result.banner_images });
        // this.setState({ headingType: result.data});
      });
    });
  }

  render() {
    const { recipes, banner_img } = this.state;
    const renderHTML = (rawHTML: string) =>
      React.createElement("div", {
        dangerouslySetInnerHTML: { __html: rawHTML },
      });

    var settings = {
      className: "",
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 7000,
      pauseOnHover: false,
      cssEase: "linear",
      adaptiveHeight: true,
    };

    return (
      <>
        <div className="container-fluid slider_top">
          <div className="row">
            <div className="col-md-4 col-12">
              <img
                src="./assets/images/banner/sr_one.jpg"
                alt=""
                className="bnr_img_30 padding_set"
              />
            </div>
            <div className="col-md-4 col-12">
              <img
                src="./assets/images/banner/sr_two.jpg"
                alt=""
                className="bnr_img_30 padding_set mobile_hide"
              />
            </div>
            <div className="col-md-4 col-12">
              <img
                src="./assets/images/banner/wellness_three.jpg"
                alt=""
                className="bnr_img_30 padding_set mobile_hide"
              />
            </div>
          </div>
        </div>

        {/* <div className="container-fluid slider_top">
            <div className="row">
              {banner_img.map((banner,i)=>{
                return(
                  <div className="col-md-4 col-12">
                <img src={`${this.state.assets_url}/uploads/${banner}`} alt="" className='bnr_img_30 padding_set' />
              </div>
                )
              })}
            </div>
      </div> */}

        <div className="container mt-5" style={{ marginTop: 50 }}>
          <div className="row">
            <div className="col-md-12">
              <div className="swin-sc swin-sc-blog-grid">
                <div className="row">
                  {recipes.map((seasonal_recipes) => (
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <div
                        data-wow-delay="0s"
                        className="blog-item swin-transition item wow fadeInUpShort"
                        style={{
                          marginBottom: 20,
                          visibility: "visible",
                          animationDelay: "0s",
                          animationName: "fadeInUpShort",
                        }}
                      >
                        <div className="blog-featured-img">
                          <img
                            src={`${this.state.assets_url}/uploads/${seasonal_recipes.recipe_image}`}
                            alt="Magma"
                            className="img img-responsive banner_img"
                          />
                        </div>
                        <div className="blog-content">
                          <div
                            className="blog-date view-more-button"
                            data-toggle="collapse"
                            data-target={`#demo_${seasonal_recipes.id}`}
                          >
                            view more
                          </div>
                          <h3 className="blog-title">
                            <a
                              href="javascript:void(0)"
                              className="swin-transition para"
                              style={{ fontSize: "22px" }}
                            >
                              {seasonal_recipes.recipe_title}
                            </a>
                          </h3>
                          <div className="product-info">
                            <table className="my_table">
                              <thead>
                                <tr>
                                  <th className="product-name">
                                    <span>Preparation time : </span>
                                    <span className="text">
                                      {seasonal_recipes.recipe_preptime}
                                    </span>
                                  </th>
                                  <th className="product-name">
                                    <span>Total time : </span>
                                    <span className="text">
                                      {seasonal_recipes.recipe_totaltime}
                                    </span>
                                  </th>
                                  <th className="product-name">
                                    <span>Serves : </span>
                                    <span className="text">
                                      {seasonal_recipes.recipe_serves}
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div
                            id={`demo_${seasonal_recipes.id}`}
                            className="collapse"
                          >
                            <div className="product-info para">
                              <h3 className="blog-title">
                                <a
                                  href="javascript:void(0)"
                                  className="swin-transition"
                                />
                              </h3>
                              <h3 className="blog-title">
                                <a
                                  href="javascript:void(0)"
                                  className="swin-transition para"
                                  style={{ fontSize: "22px" }}
                                >
                                  {seasonal_recipes.description_title}
                                </a>
                              </h3>
                              <div>
                                {renderHTML(seasonal_recipes.description)}
                              </div>
                            </div>

                            <h3 className="blog-title">
                              <a
                                href="javascript:void(0)"
                                className="swin-transition para"
                                style={{ fontSize: "22px" }}
                              >
                                Ingredients
                              </a>
                            </h3>
                            <div className="para">
                              <div>
                                {renderHTML(
                                  seasonal_recipes.recipe_ingredients
                                )}
                              </div>
                            </div>
                            <div className="product-info para">
                              <h3 className="blog-title">
                                <a
                                  href="javascript:void(0)"
                                  className="swin-transition"
                                />
                              </h3>
                              <h3 className="blog-title">
                                <a
                                  href="javascript:void(0)"
                                  className="swin-transition para"
                                  style={{ fontSize: "22px" }}
                                >
                                  Directions
                                </a>
                              </h3>
                              <div>
                                {renderHTML(seasonal_recipes.recipe_method)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </>
    );
  }
}
