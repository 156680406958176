import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <>
        <footer>
          <div className="" style={{ background: "#737070" }}>
            <div className="container">
              <div className="ft-widget-area footer_main_div">
                <div className="row">
                  <div className="col-md-3 col-sm-6">
                    <div className="ft-area1">
                      <div className="swin-widget swin-widget-about">
                        <div className="clerfix">
                          <div className="header-logo">
                            <img
                              src="./assets/images/logo/logo1.png "
                              alt="foodweb sydney logo"
                              className="logo-img site_logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-md-6 col-sm-6">
                          <div className="ft-area1">
                            <div className="swin-widget widget-about">
                              <div className="title-widget footer_heading">
                                Vibe Seventeen Manager{" "}
                                <span className="h6">(Cameron Jones)</span>
                              </div>
                              <div className="about-contact-info clearfix">
                                <div className="info-content phone-content">
                                  <div className="info-icon">
                                    <i className="fa-icon fa fa-phone"></i>
                                  </div>
                                  <div className="info-text">
                                    <p>0474314434</p>
                                  </div>
                                </div>
                                <div className="info-content email-content">
                                  <div className="info-icon">
                                    <i className="fa-icon fa fa-envelope"></i>
                                  </div>
                                  <div className="info-text link-white">
                                    <a href="mailto:employee.cafe.sydney@jpmorgan.com">
                                      employee.cafe.sydney@jpmorgan.com
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="ft-area1">
                            <div className="swin-widget widget-about">
                              <div className="title-widget footer_heading">
                                Client Centre{" "}
                              </div>
                              <div className="about-contact-info clearfix">
                                <div className="info-content phone-content">
                                  <div className="info-icon">
                                    <i className="fa-icon fa fa-phone"></i>
                                  </div>
                                  <div className="info-text">
                                    <p>+61290038872</p>
                                  </div>
                                </div>
                                <div className="info-content email-content">
                                  <div className="info-icon">
                                    <i className="fa-icon fa fa-envelope"></i>
                                  </div>
                                  <div className="info-text link-white">
                                    <a href="mailto:client.centre.syndey@jpmorgan.com">
                                      client.centre.syndey@jpmorgan.com
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
