import React, { Component } from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import Slider from "react-slick";

class Work_Cafes extends Component {
  constructor() {
    super();
    this.state = {
      banner_img: [],
      base_url: global.BaseUrl,
      assets_url: global.AssetsUrl,
    };
  }

  componentDidMount() {
    fetch(`${this.state.base_url}/getBannerById/11`).then((resp) => {
      resp.json().then((result) => {
        console.log(result);
        this.setState({ banner_img: result.banner_images });
        // this.setState({ headingType: result.data});
      });
    });
  }

  render() {
    const { banner_img } = this.state;

    var settings = {
      className: "",
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 7000,
      pauseOnHover: false,
      cssEase: "linear",
      adaptiveHeight: true,
    };

    return (
      <>
        <div className="container-fluid slider_top">
          <div className="row">
            <div className="col-md-4 col-12">
              <img
                src="./assets/images/banner/work_one.jpg"
                alt=""
                className="bnr_img_30 padding_set"
              />
            </div>
            <div className="col-md-4 col-12">
              <img
                src="./assets/images/banner/work_two.jpg"
                alt=""
                className="bnr_img_30 padding_set mobile_hide"
              />
            </div>
            <div className="col-md-4 col-12">
              <img
                src="./assets/images/banner/work_three.jpg"
                alt=""
                className="bnr_img_30 padding_set mobile_hide"
              />
            </div>
          </div>
        </div>
        <section className="section-reservation-form padding-bottom-100">
          <div class="container">
            <div class="swin-sc swin-sc-title style-2">
              <h3 class="title">
                <span>WORK CAFES</span>
                <div className="red-line-break"></div>
              </h3>
              <p class="cafe-text text_justify">
                Work cafés have been aligned with new global standards embracing
                thematic design elements to create out of Workplace area
                concepts &amp; to enhance overall experience.
              </p>
            </div>

            <div class="reservation-form">
              <div class="row">
                <div class="col-md-8 col-md-offset-2">
                  <h4 class="service-title text-center">
                    What Do Work Cafes Offer:
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="col-md-12">
                    <ul class="list-style">
                      <li>
                        Tea/Coffee Vending - Wide variety of hot coffees from
                        Franke machines using Waddi Ying medium roasted beans
                        sourced from an indigenous supplier and a handpicked
                        selection of teas.
                      </li>
                      <li>
                        Hydration Station - Water with options of hot and cold
                        water
                      </li>

                      <li>Refrigerators and microwaves available</li>
                      <li>
                        Savory cracker selection including Gluten Free options
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="swin-sc swin-sc-contact-form light mtl"></div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Work_Cafes;
