import React from "react";

const Disclaimer = () => {
  return (
    <div
      className="container mt-5 "
      style={{ padding: "40px 0", color: "#111" }}
    >
      <h2 className="text-center mb-6" style={{ marginBottom: "40px" }}>
        Disclaimer
      </h2>
      <ul>
        <li>
          The pictures used in this webpage are for representation purpose only.
        </li>
        <li>
          An expert third-party supplier has been engaged to conduct periodic
          Hygiene Inspections and Food Sample Collection.
        </li>
        <li>
          An allergen-free meal requires an extremely controlled environment
          which may not be available while cooking a huge number of meals.
          Although utmost care is taken for all areas of food production, the
          products prepared in our kitchen may have come into contact with the
          most common allergens. Employees having food allergies are advised to
          use their discretion while consuming food prepared in the in-house
          kitchens. If your allergic reactions are severe, it is advised to
          consume home-cooked food only.
        </li>
        <li>Orders once placed cannot be cancelled.</li>
      </ul>
    </div>
  );
};

export default Disclaimer;
